import * as React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
        <div className="container">
            <div className="row mb-5 col col-12 col-lg-12 col-md-12 col-sm-12 footer-links">
                <div className="col col-lg-3 col-sm-6">
                    <p className="title mb-2">REMOCO</p>
                    <p>Built with dedication & love<br/>by two experienced team leads</p>
                </div>
                <div className="col-6 col-lg-3 col-sm-6 mb-3">
                    <p className="title mb-2">PRODUCT</p>
                    <ul>
                        <li className="mb-1">
                            <Link to="/features" className="track-footer-menu-link">
                                <i className="fas fa-gem"></i>
                                <span className="ml-2">Features</span>
                            </Link>
                        </li>
                        {/*
                        <li className="mb-1">
                            <Link to="/bot" className="track-footer-menu-link">
                                <i className="fas fa-robot"></i>
                                <span className="ml-2">RemocoBot</span>
                            </Link>
                        </li>
                        */}
                        <li className="mb-1">
                            <Link to="/#pricing" className="track-footer-menu-link">
                                <i className="fas fa-dollar-sign"></i>
                                <span className="ml-2">Pricing</span>
                            </Link>
                        </li>
                        {/*
                        <li className="mb-1">
                            <Link to="/case-studies" className="track-footer-menu-link">
                                <i className="fas fa-user-ninja"></i>
                                <span className="ml-2">Case Studies</span>
                            </Link>
                        </li>
                    */}
                    </ul>
                </div>
                <div className="col-6 col-lg-3 col-sm-6">
                    <p className="title mb-2">COMPANY</p>
                    <ul>
                        <li className="mb-1">
                            <Link to="/about" className="track-footer-menu-link">
                                <i className="fas fa-users"></i>
                                <span className="ml-2">About us</span>
                            </Link>
                        </li>
                        <li className="mb-1">
                            <Link to="/#contact-us" className="track-footer-menu-link">
                                <i className="far fa-envelope"></i>
                                <span className="ml-2">Contact us</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-6 col-lg-3 col-sm-6">
                    <p className="title mb-2">HELP</p>
                    <ul>
                        <li className="mb-1 track-footer-menu-link">
                            <Link to="/faq">
                                <i className="fas fa-question-circle"></i>
                                <span className="ml-2">FAQ</span>
                            </Link>
                        </li>
                        <li className="mb-1 track-footer-menu-link">
                            <Link to="/#contact-us">
                                <i className="fas fa-user-graduate"></i>
                                <span className="ml-2">Request a Demo</span>
                            </Link>
                        </li>
                        <li className="mb-1 track-footer-menu-link">
                            <Link to="/terms">
                                <i className="far fa-file-alt"></i>
                                <span className="ml-2">Terms Of Service</span>
                            </Link>
                        </li>
                        <li className="mb-1 track-footer-menu-link">
                            <Link to="/privacy">
                                <i className="far fa-file-alt"></i>
                                <span className="ml-2">Privacy policy</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <ul className="social">
                        <li>
                            <a href="https://www.facebook.com/remoco.io/" target="_blank" rel="noreferrer" className="track-social-menu-link">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/remoco_io/" target="_blank" rel="noreferrer" className="track-social-menu-link">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/remoco.io/" target="_blank" rel="noreferrer" className="track-social-menu-link">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <p className="copyright">&copy; 2022 REMOCO</p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer