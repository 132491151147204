import React, { useState } from 'react';
import { Link } from "gatsby"

const Header = (props) => {

    const [classes, setClasses] = useState({
        nav: "d-none",
        menu: ""
    });

    const triggerMenu = (e) => {
        let update = {
            nav: "d-block",
            menu: "active"
        }
        if (classes.menu) {
            update = {
                nav: "d-none",
                menu: ""
            }
        }
        setClasses(update)
    }

    const menu = props.menu
    return (
        <header className="header-area header-sticky">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">
                            <Link to="/" className="logo">
                                <img src={`/logo.svg`} alt="Remoco" height="53" width="140"/>
                            </Link>
                            <ul className={`nav ${classes.nav}`}>
                                {menu.map((item, i) => {
                                    const className = item.class ? item.class : "track-menu-link"
                                    //const modal = item.modal ? "asModal" : ""
                                    return (
                                        <li key={`header-link-${i}`}>
                                            <Link to={item.link} className={className}>{item.text}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                            <Link to="/" className={`menu-trigger ${classes.menu}`} onClick={triggerMenu}>
                                <span>Menu</span>
                            </Link>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
